import {Vue} from "vue-property-decorator"
import Promo from "@/models/Promo"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import SnackbarModule from "@/store/SnackbarModule"

export default class PromoService {

    static getPromos(component: Vue, promos: Promo[]) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/promos")
            .then((response: any) => {
                let list = JsonTool.jsonConvert.deserializeArray(response.data, Promo)
                promos.splice(0, promos.length)
                list.forEach(p => promos.push(p))
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se han podido obtener las promociones"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getPromo(component: Vue, promoId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/promos/" + promoId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then((response: any) => {
                let entity = JsonTool.jsonConvert.deserializeObject(response.data, Promo)
                // @ts-ignore
                component.promo = entity
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener la promoción"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

}
