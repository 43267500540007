






















































































import {Component, Ref, Vue, Watch} from "vue-property-decorator"
import ClientService from "@/services/ClientService"
import Client from "@/models/Client"
import Options from "@/models/vue/Options"
import SessionModule from "@/store/SessionModule"
import ClientDialogComponent from "@/components/ClientDialogComponent.vue"
import ClientTab from "@/components/tabs/ClientTab.vue"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import StringTool from "@/services/tool/StringTool"

@Component( { components: { ClientDialogComponent, ClientTab  } } )
export default class ClientsTab extends Vue {

  @Ref() readonly form!: HTMLFormElement

  lang: any = getModule(LangModule).lang
  step: number = 0
  loading: boolean = false
  clients: Client[] = []
  selectedClient?: Client = new Client
  dialog: boolean = false
  active: boolean = false
  all: boolean = false
  search: string = ''
  page: number = 1
  itemsPerPage: number = 10
  options: Options = new Options()

  sessionModule: SessionModule = getModule(SessionModule)
  isCommercial = this.sessionModule.session.authorities.map(e => e.title == "commercial")[0]
  
  headers = [
    // { text: 'Id', align: 'center', value: 'id', width: '15%' },
    { text: this.lang.email, align: 'center', value: 'email', width: '25%' },
    { text: this.lang.name, align: 'center', value: 'name', width: '25%' },
    { text: this.lang.commercialName, align: 'center', value: 'commercialName', width: '35%' },
  ]

  async created() {
    this.refresh()
  }

  refresh() {
   this.step = 0
    this.watchOptions()
  }

  openClient(client: Client) {
    this.selectedClient = client
    this.step = 1
  }

  openCreateDialog() {
    if (this.form) this.form.resetValidation()
    this.dialog = true
    // this.client = new Client()
  }

  closeDialog() {
    this.dialog = false
  }

  @Watch("options")
  watchOptions() {
    if (this.isCommercial) {
      ClientService.getClients(this, this.clients,this.page - 1, this.itemsPerPage, this.search, true, !this.active);
    } else {
      ClientService.getClients(this, this.clients,this.page - 1, this.itemsPerPage, this.search, this.all, !this.active);
    }
  }

  @Watch("active")
  watchActive() {
      this.watchOptions()
  }

  @Watch("all")
  watchAll() {
      this.watchOptions()
  }

}
