import {JsonObject, JsonProperty} from "json2typescript";
import InvoiceLine from "@/models/InvoiceLine";

@JsonObject("Invoice")
export default class Invoice {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("clientId", String, true)
    clientId?: string = undefined
    @JsonProperty("total", Number, true)
    total?: number = undefined
    @JsonProperty("date", String, true)
    date?: string = undefined
    @JsonProperty("invoiceLines", [InvoiceLine], true)
    invoiceLines?: InvoiceLine[] = undefined

}
