































import LangModule from "@/store/LangModule"
import { Component, Vue } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
 
@Component({})
export default class NotificationsTab extends Vue {

  lang: any = getModule(LangModule).lang
  disabled: boolean = true

}
