
































































import Client from "@/models/Client"
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import OrderService from "@/services/OrderService";
import Order, {OrderSelect, OrderType} from "@/models/Order";
import OrderDetail from "@/models/OrderDetail";
import OrderStatusComponent from "@/components/OrderStatusComponent.vue"

@Component({ components: { OrderStatusComponent } })
export default class ClientOrdersTab extends Vue {

	@Prop() readonly client!: Client
	lang: any = getModule(LangModule).lang
	loading: boolean = false
	orders: Order[] = []
	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 10
	totalItems: number = 0

	headers = [
		{ text: this.lang.orderReferenceHeader, align: 'center', value: 'ref', width: 'auto' },
		{ text: this.lang.buyDateAtHeader, align: 'center', value: 'createdAt', width: 'auto' },
		{ text: this.lang.stateHeader, align: 'center', value: 'state', width: 'auto' },
		{ text: this.lang.total, align: 'center', value: 'totalAmount', width: 'auto' }
	]

	orderTypes: OrderSelect[] = [
		{name: this.lang.waitingStatus, value: OrderType.WAITING},
		{name: this.lang.sendStatus, value: OrderType.SEND},
		{name: this.lang.finishStatus, value: OrderType.FINISH},
		{name: this.lang.draftStatus, value: OrderType.DRAFT},
	]

	indicators = [
		{ name: this.lang.inProgressStatus, color: "purple lighten-3" },
		{ name: this.lang.sendStatus, color: "blue lighten-2" },
		{ name: "Por confirmar", color: "deep-purple lighten-1" },
		{ name: "Retrasado", color: "grey" },
		{ name: "Cancelado", color: "light-blue lighten-4" }
	]

	created() {
		this.refresh()
	}

	async refresh() {
		await OrderService.getMyOrders(this, this.orders, this.page - 1, this.itemsPerPage, null, null, null, this.client.id, null, null)
		this.$emit('refresh')
	}

	getTotalPrice(orderDetails: OrderDetail[]) {
		let totalPrice: number = 0
		orderDetails.forEach(orderDetail => { totalPrice += (Number(orderDetail.product!.commercialRate) * Number(orderDetail.quantity)) })
		return totalPrice
	}

	rowClick(item: Order) {
	}

	@Watch('client')
	onClientChanged() {
		this.refresh()
	}

}
