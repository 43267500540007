var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"disable-sort":"","mobile-breakpoint":0,"height":"400px","fixed-header":"","headers":_vm.headers,"items":_vm.orders,"hide-default-footer":"","no-data-text":_vm.loading ? _vm.lang.searching : _vm.lang.noResults},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.ref",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.state",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.totalAmount",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.reference)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd MMMM yyyy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('OrderStatusComponent',{attrs:{"orderType":item.orderType}})],1)]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.getTotalPrice(item.orderDetails).toFixed(2))+"€ ")])]}}])},[_c('template',{slot:"footer"},[_c('v-divider',{staticClass:"mb-4"}),_c('div',{staticClass:"d-none d-sm-flex align-center text-center justify-space-around"},_vm._l((_vm.indicators),function(item,key){return _c('div',{key:key},[_c('span',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v("mdi-square")]),_vm._v(_vm._s(item.name))],1)])}),0),_c('v-divider',{staticClass:"mt-4"})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }