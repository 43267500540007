import {Vue} from "vue-property-decorator"
import Invoice from "@/models/Invoice"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import SnackbarModule from "@/store/SnackbarModule"


export default class InvoiceService {

    static async getInvoices(component: Vue, invoices: Invoice[], page: number, size: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/invoices", {
                params: { page, size },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Invoice)
            invoices.splice(0, invoices.length)
            list.forEach(p => invoices.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
            // @ts-ignore
            component.loading = false
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se han podido obtener las facturas")
            // @ts-ignore
            component.loading = false
        }
    }

    static async getInvoice(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/invoices" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.invoice = JsonTool.jsonConvert.deserializeObject(response.data, Invoice);
            // @ts-ignore
            component.loading = false;
        } catch (err) {
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se pudo obtener la factura")
        }
    }

    static async synchronizeInvoices(component: Vue) {
        // @ts-ignore
        component.loading = true
        try {
            await component.axios.post(ConstantTool.BASE_URL + "/api/invoices", null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.loading = false;
            // @ts-ignore
            component.refresh()
        } catch (err) {
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se pudo sincronizar las facturas")
        }
    }

}