import {JsonObject, JsonProperty} from "json2typescript";
import Product from "@/models/Product";

@JsonObject("InvoiceLine")
export default class InvoiceLine {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("quantity", String, true)
    quantity?: string = undefined
    @JsonProperty("price", String, true)
    price?: string = undefined
    @JsonProperty("title", String, true)
    title?: string = undefined
    @JsonProperty("product", Product, true)
    product?: Product = undefined

}
