import User from "@/models/User"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import {Vue} from "vue-property-decorator"
import JsonTool from "@/services/tool/JsonTool"
import SnackbarModule from "@/store/SnackbarModule"
import {AuthorityName} from "@/models/Authority"
import axios from "axios";
import Response from "@/models/responses/Response";

export default class UserService {

    static async getMeAsUser(): Promise<Response<User>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/users/@me", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const user = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: user })
        } catch (e) { return Promise.reject(e) }
    }




    static getMyDistributor(component: Vue, userId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/@me/distributors/"+ userId +"/users/", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let entity = JsonTool.jsonConvert.deserializeObject(response.data, User)
                // @ts-ignore
                component.commercial = entity
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener el distribuidor"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static patchUser(component: Vue, user: User) {
        // @ts-ignore
        component.loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/@me/users/" + user.id + "/update",
            user, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                // @ts-ignore
                component.refresh()
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar el usuario"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static patchChangePassword(component: Vue, password: string, newPassword: string, userId: number) {
        let formData: FormData = new FormData()
        formData.set("password", password)
        formData.set("newPassword", newPassword)
        component.axios.patch(ConstantTool.BASE_URL + "/api/users/" + userId + "/change-password",
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                getModule(SnackbarModule).makeToast("Contraseña cambiada con exito")
                // @ts-ignore
                component.changePasswordDialog = false
            })
            .catch(error => getModule(SnackbarModule).makeToast("No se pudo cambiar la contraseña"))
    }

    static async getMyDistributors(component: Vue, users: User[], page: number, size: number) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/distributors/users", {
                params: {page, size},
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            let list = JsonTool.jsonConvert.deserializeArray(response.data, User);
            users.splice(0, users.length);
            list.forEach(p => users.push(p));
            // @ts-ignore
            component.loading = false;
        } catch (err) {
            // @ts-ignore
            component.loading = false
            console.log(err)
            getModule(SnackbarModule).makeToast("No se han podido obtener los clientes")
        }
    }

}
