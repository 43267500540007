var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:this.$vuetify.breakpoint.smAndDown ? '' : 'px-7',attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-iterator',{attrs:{"items":_vm.invoices,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"no-data-text":_vm.loading ? _vm.lang.searching : _vm.lang.notFoundInvoices,"no-results-text":_vm.loading ? _vm.lang.searching : _vm.lang.notFoundInvoices,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}}),(_vm.invoices.length != 0)?_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pageCount,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()]},proxy:true}])},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"disable-pagination":"","hide-default-footer":"","disable-sort":"","mobile-breakpoint":0},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.connectorId",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.date",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.total",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"header.ver",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"grey lighten-2 py-2 body-1 font-weight-medium"},[_vm._v(_vm._s(header.text))])]}},{key:"item.connectorId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-1 grey--text text--darken-3"},[_vm._v(_vm._s(item.connectorId))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-1 grey--text text--darken-3"},[_vm._v(_vm._s(item.date))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-1 grey--text text--darken-3"},[_vm._v(_vm._s(item.total)+"€")])]}}])})],1)],1)],1),_c('BillDetailsDialog',{attrs:{"dialog":_vm.dialog,"invoice":_vm.selectedInvoice},on:{"close":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }