






















import {Component, Vue} from "vue-property-decorator"
import Promo from "@/models/Promo"
import PromoService from "@/services/PromoService"
import FileSaver from "file-saver"

@Component
export default class PromotionsTab extends Vue {

  loading: boolean = false
  promos: Promo[] = []

  created() {
    PromoService.getPromos(this, this.promos)
  }

  openUrl(url: string) {
    window.open(url, "_blank")
  }

  savePDF(url: string) {
    var oReq = new XMLHttpRequest()
    oReq.open("GET", url, true);
    oReq.responseType = "blob"
    oReq.onload = function() {
      let file = new Blob([oReq.response], { type: 'application/pdf' })
      FileSaver.saveAs(file, url.substring(url.lastIndexOf('/')+1, url.length))
    }
    oReq.send()
  }
}
