




























































import Client from "@/models/Client"
import {Component, Prop, Vue} from "vue-property-decorator"
import ClientAddressTab from "@/components/tabs/ClientAddressTab.vue"
import Dialog from "@/models/vue/Dialog"
import DialogModule from "@/store/DialogModule"
import { getModule } from "vuex-module-decorators"
import ClientService from "@/services/ClientService"
import LangModule from "@/store/LangModule"
import ClientOrdersTab from "@/components/tabs/ClientOrdersTab.vue";

@Component({ components: { ClientAddressTab, ClientOrdersTab} })
export default class ClientTab extends Vue {

  @Prop() readonly client!: Client
  tab: number = 0
  lang: any = getModule(LangModule).lang

  activateClient() {
    getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.client.enable ? this.lang.deactivateClientConfirmation : this.lang.activateClientConfirmation, () => {
      ClientService.activateClient(this, this.client.id!, !this.client.enable)
    }))
  }

  refresh() {
    this.$emit('refresh')
  }

}
