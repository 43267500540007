





















































































import Company from "@/models/Company"
import Dialog from "@/models/vue/Dialog"
import CompanyService from "@/services/CompanyService"
import LoginService from "@/services/LoginService"
import StringTool from "@/services/tool/StringTool"
import UserService from "@/services/UserService"
import DialogModule from "@/store/DialogModule"
import LangModule from "@/store/LangModule"
import SessionModule from "@/store/SessionModule"
import SnackbarModule from "@/store/SnackbarModule"
import { Component, Ref, Vue } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import User from "@/models/User";

@Component
export default class PersonalData extends Vue {

  sessionModule: SessionModule = getModule(SessionModule)
  lang: any = getModule(LangModule).lang

  user = this.sessionModule.session.user

  @Ref() readonly changePasswordForm!: HTMLFormElement
  profileFormValid: boolean = true
  company: Company = new Company()
  newPassword: string = ""
  match: string = ""

  passwordForm = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  }

  basicRules = [
    (input: string | undefined) => (input != undefined ? true : this.lang.emptyLabel),
    (input: string  | undefined) => input != undefined && input.length >= 4 ? true : StringTool.replaceDynText(this.lang.minimumLength, "%numberSlot%", "4"),
    (input: string  | undefined) => input != undefined && input.length <= 250 ? true : this.lang.maximusLength,
  ]

  rules = {
		required: (value: string) => !!value || this.lang.emptyLabel,
		min4Characters: (value: string) => value.length > 5 || StringTool.replaceDynText(this.lang.minimumLength, "%numberSlot%", "4")
	}

  confirmPasswordRules = [
    (input: string | undefined) => (input == this.passwordForm.newPassword ? true : this.lang.notMatchingPasswords)
  ]

  validateForm(form: HTMLFormElement) {
    let invalidInput: string = ""

    for (const input of form.inputs) {
      if (!input.valid) { invalidInput = input.label }
    }

    if (!form.validate()) {
      getModule(SnackbarModule).makeToast(`El campo de ${invalidInput} es invalido.`);
    } else {
      return true
    }
    return false
  }

  updatePassword() {
    if (this.changePasswordForm.validate()) {
      getModule(DialogModule).showDialog(
        new Dialog(this.lang.warning, this.lang.updatePasswordConfirmation, () =>
          UserService.patchChangePassword(this, this.passwordForm.oldPassword, this.newPassword, this.user.id!)
        )
      )
    }
  }
}
