

































import LangModule from "@/store/LangModule"
import {Component, Vue} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import ProductListComponent from "../ProductListComponent.vue"
import Product from '@/models/Product'
import FavoriteProductComponent from "@/components/products/FavoriteProductComponent.vue";
import ProductService from "@/services/ProductService";
import SessionModule from "@/store/SessionModule";
import ProductDialog from "@/components/dialogs/ProductDialog.vue";
import jsPDF from "jspdf"
// @ts-ignore
import VueHtml2pdf from 'vue-html2pdf'
import FavouritesModule from "@/store/FavouritesModule";

@Component( { components : { FavoriteProductComponent, ProductDialog, VueHtml2pdf } } )
export default class FavouriteTab extends Vue {

  lang: any = getModule(LangModule).lang
	favorites: any = getModule(FavouritesModule).favoriteProducts

	sessionModule: SessionModule = getModule(SessionModule)
	product: Product = new Product()
	products: Product[] = []
	selectedProduct = null
	loading: boolean = false
	totalItems: number = 0
	productDialog: boolean = false

  headers = [
    { text: this.lang.product, align: 'center', value: 'title', width: '40%' },
    { text: this.lang.price, align: 'center', value: 'price', width: '15%' },
    { text: this.lang.quantity, align: 'center', value: 'amount', width: '15%' },
    { text: this.lang.stock, align: 'center', value: 'stock', width: '15%' },
    { text: this.lang.action, align: 'center', value: 'action', width: '15%' }
  ]
  items = [
    { picture: "@/assets/pluma_green.jpg", title: "PLUMA COMPACT · Pastel green", description: "Secador profesional.", itemId: "BSEC42675", price: "57,95€", amount: 0, action: "57,95€" },
    { picture: "@/assets/pluma_green.jpg", title: "PLUMA COMPACT · Pastel green", description: "Secador profesional.", itemId: "BSEC42675", price: "57,95€", amount: 0, action: "57,95€" },
    { picture: "@/assets/pluma_green.jpg", title: "PLUMA COMPACT · Pastel green", description: "Secador profesional.", itemId: "BSEC42675", price: "57,95€", amount: 0, action: "57,95€" },
    { picture: "@/assets/pluma_green.jpg", title: "PLUMA COMPACT · Pastel green", description: "Secador profesional.", itemId: "BSEC42675", price: "57,95€", amount: 0, action: "57,95€" },
    { picture: "@/assets/pluma_green.jpg", title: "PLUMA COMPACT · Pastel green", description: "Secador profesional.", itemId: "BSEC42675", price: "57,95€", amount: 0, action: "57,95€" },
  ]

	create() {

	}

	refresh() {
		getModule(FavouritesModule).fetchFavoriteProducts()
	}

	openProductDialog(product: Product) {
		this.productDialog = true
		this.product = product
		this.product.quantity == 0 ? this.product.quantity = 1 : null
	}

}
