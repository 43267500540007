




















import {Vue, Component, Mixins, PropSync, Watch} from 'vue-property-decorator';
import SessionMixin from "@/mixins/SessionMixin";
import LangMixin from "@/mixins/LangMixin";
import {Tab} from "@/services/tool/TabTool";
import TabLists from "@/services/tool/TabLists";

@Component
export default class SettingsTabsListComponent extends Mixins(SessionMixin, LangMixin) {
  tab: number = 0

  @PropSync("currentTab") syncedCurrentTab!: Tab

  created() {
    this.syncedCurrentTab = TabLists.personalTab
  }

  get currentTab() {
    return this.tabList[this.tab]
  }

  get tabList(): Tab[] {
    let list: Tab[] = []

    list.push(TabLists.personalTab)

    if (this.isCommercial || this.isDistributor) {
      list = [...list, ...TabLists.settingsOrderList]
    }

    if (this.isHeadquarterManager) {
      list = [...list, ...TabLists.headquarterTabs]
    }

    list.push(TabLists.logoutTab)

    return list
  }

  @Watch("tab")
  onTabChanged() {
    console.log(this.currentTab)
    this.syncedCurrentTab = this.currentTab
  }

}
