


















































import {Component, Mixins, Vue, Watch} from "vue-property-decorator"
import AccountBoxComponent from "@/components/AccountBoxComponent.vue"
import PersonalDataTab from "@/components/tabs/PersonalDataTab.vue"
import AddressTab from "@/components/tabs/AddressTab.vue"
import OrdersTab from "@/components/tabs/OrdersTab.vue"
import BillsTab from "@/components/tabs/BillsTab.vue"
import PromotionsTab from "@/components/tabs/PromotionsTab.vue"
import NotificationsTab from "@/components/tabs/NotificationsTab.vue"
import FavouriteTab from "@/components/tabs/FavouriteTab.vue"
import ClientsTab from "@/components/tabs/ClientsTab.vue"
import LogoutTab from "@/components/tabs/LogoutTab.vue"
import TabModule from "@/store/TabModule"
import TabTool, {Tab} from "@/services/tool/TabTool"
import { getModule } from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import LangModule from "@/store/LangModule"
import CompanyIcon from "@/assets/custom_icons/company.svg"
import SettingsTabsComponent from "@/components/SettingsTabsComponent.vue";
import SessionMixin from "@/mixins/SessionMixin";
import LangMixin from "@/mixins/LangMixin";
import TabLists from "@/services/tool/TabLists";
import SettingsTabsListComponent from "@/components/SettingsTabsListComponent.vue";
import LmaoBOx from "@/components/tabs/LbaoBox.vue";
import HeadquarterOrdersTab from "@/components/tabs/HeadquarterOrdersTab.vue";

@Component({components: {
    HeadquarterOrdersTab: HeadquarterOrdersTab,
    SettingsTabsListComponent,
		AccountBoxComponent,
		PersonalDataTab,
		AddressTab, OrdersTab,
		BillsTab,
		FavouriteTab,
		NotificationsTab,
		PromotionsTab,
		LogoutTab,
		ClientsTab,
	}})

export default class SettingsView extends Mixins(SessionMixin, LangMixin) {

  sessionModule: SessionModule = getModule(SessionModule)
  user = this.sessionModule.session.user

  currentTab: Tab = new Tab()

  tab: Tab = new Tab()

  // get tab() { return this.tabModule.tab }
  // set tab(tab: number) { this.tabModule.setTab(tab) }

  @Watch("currentTab")
  onCurrentTabChanged() {
    this.tab = this.currentTab
  }

  //Todo: Reindex route update per tab implementation

  // setTab(val: any) {
  //   this.tab = val.index
  // }

  // created() {
  //   this.onRouteChanged()
  // }

  // setRouteByTab() {
  //   TabTool.setRouteByTab(this.tab, this)
	// 	console.log(this.tab)
	// }

	// @Watch('$route.path')
	// onRouteChanged() {
	// 	if (this.$route.params.tabName) {
	// 		if (this.isCommercial && this.$route.params.tabName == "bills") {
	// 			this.tab = 0
	// 			this.activeTab = 0
	// 		} else {
	// 			this.tab = this.tabList.find(tab => tab.key == this.$route.params.tabName)!.index!!
	// 			this.activeTab = this.tabList.findIndex(tab => tab.key == this.$route.params.tabName)
	// 		}
	// 	}
	// }
  
  // @Watch('tab')
  // onTabChanged() {
  //   this.setRouteByTab()
	// }

}
