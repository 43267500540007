var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(_vm._s(_vm.lang.createAddress))])],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-data-table',{staticClass:"full-width",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.addresses,"items-per-page":5,"loading":_vm.loading,"disable-sort":"","fixed-header":"","height":"400px"},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-bold"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.address",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-bold"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-bold"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteAddress(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.addressDetail(item)}}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}])})],1),_c('AddressDialog',{attrs:{"dialog":_vm.dialog,"client":_vm.client},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }