var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 mt-2",attrs:{"fluid":""}},[_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":0}},[_c('v-container',{staticClass:"ma-0 pt-1",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","dense":"","clearable":"","append-icon":"mdi-magnify","label":_vm.lang.search},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.watchOptions($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{class:("d-flex align-center " + (_vm.isCommercial ? 'justify-end' : 'justify-space-between'))},[_c('v-switch',{staticClass:"mr-2",attrs:{"flat":"","inset":"","label":_vm.lang.deactivatedClients},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}}),_c('v-spacer'),_c('v-btn',{staticClass:"px-8 py-4",attrs:{"depressed":"","color":"pb-success","tile":""},on:{"click":function($event){return _vm.openCreateDialog()}}},[_vm._v(_vm._s(_vm.lang.createClient))])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 text-center",attrs:{"search":_vm.search,"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.clients,"no-data-text":"No hay clientes disponibles."},on:{"click:row":_vm.openClient},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-bold"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.email",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-bold"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-bold"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.commercialName",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-bold"},[_vm._v(_vm._s(header.text))])])],1)],1)]}}])}),_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}})],1)],1)],1)],1),_c('v-window-item',{attrs:{"value":1}},[_c('v-container',[_c('v-row',[_c('v-card',{staticClass:"greySecondary",attrs:{"width":"100%","flat":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.step = 0}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('span',{staticClass:"grey--text text--darken-3 font-weight-bold mx-4"},[_vm._v(_vm._s(_vm.lang.clientInfo))])],1)],1)],1),_c('v-row',[_c('ClientTab',{attrs:{"client":_vm.selectedClient},on:{"refresh":function($event){return _vm.refresh()}}})],1)],1)],1)],1),_c('ClientDialogComponent',{attrs:{"dialog":_vm.dialog,"refresh":_vm.refresh},on:{"closeDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }